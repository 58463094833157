@import './variables';

.btn {
  color: map-get($colors, 'black');
  box-sizing: border-box;
  display: inline-block;
  border: none;
  padding: 1.55rem 2.8rem;
  background-color: transparent;
  text-align: center;
  border-radius: 2.5rem;
  outline: none;
  font-weight: 500;
  cursor: pointer;
  &:active,
  &.active {
    opacity: 0.85;
  }
  &:disabled,
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  @include default-font-settings;
  line-height: initial;
  &-primary {
    @extend .btn;
    min-width: 160px;
    color: var(--color-button-primary);
    background-color: var(--color-primary);
    transition: background-color 150ms ease-in-out;
  }
  &-secondary {
    @extend .btn;
    // padding reduced to account for button border while remaning the same height as other buttons
    padding: 1.25rem 2.8rem;
    color: $black;
    border: 1.5px solid $black;
  }
  &-icon {
    @extend .btn;
    padding: 0;
    font-size: 2.5rem;
    line-height: 0;
    font-weight: 400;
    &:active,
    &.active {
      color: var(--color-primary);
    }
  }

  &-link {
    border: none;
    background: transparent;
    color: var(--color-primary);
    font-size: 1.6rem;
    font-weight: 600;
    text-align: left;
    outline: none;
    cursor: pointer;
  }

  &-answer {
    @extend .btn;
    position: relative;
    display: block;
    padding: 1.6rem 1rem;
    border: 1px solid $black;
    border-radius: 0.4rem;
    text-align: left;
    background-color: transparent;
    // overflow: hidden;
    transition: all 200ms ease-in-out;
    &:before {
      display: none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-primary);
      transition: all 200ms ease-in-out;
      opacity: 0.05;
    }
    &:active,
    &.active {
      border-color: var(--color-primary);
      &:before {
        display: block;
      }
    }
  }
}

.ng-valid {
  .btn-secondary {
    border-color: var(--color-primary);
  }
}

a[class^="btn-"],
a[class*="btn-"] {
  display: inline-block;
  text-decoration: none;
}
