/*
  COLORS
*/
$white: #ffffff;
$black: #000000;
$gray-light: #efefef;
$gray-medium-light: #d1d1d1;
$gray-medium: #c5c3c1;
$gray-dark: #9a9a9a;
$orange: #ff5400;
$blue: #1d50fc;
$green-light: #00c3a1;
$green-dark: #009c81;
$dark-blue: #505f75;
$red: red;

$font-family: 'Work Sans', 'Arial', sans-serif;

$colors: (
  "primary": $orange,
  "white": $white,
  "black": $black,
  "blue": $blue,
  "gray-light": $gray-light,
  "gray": $gray-medium,
  "gray-dark": $gray-dark,
);

/*
  FONTS
*/
@mixin heading-1-font-settings {
  font-size: 2.3rem;
}

@mixin heading-2-font-settings {
  font-size: 1.6rem;
}

@mixin default-font-settings {
  font-size: 1.6rem;
}

@mixin default-label-font-settings {
  font-size: 1.3rem;
}
