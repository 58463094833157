@font-face {
  font-family: 'Work Sans';
  font-weight: 400;
  font-style: normal;
  src: url(../assets/fonts/static/WorkSans-Regular.ttf);
}

@font-face {
  font-family: 'Work Sans';
  font-weight: 500;
  font-style: normal;
  src: url(../assets/fonts/static/WorkSans-Medium.ttf);
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Work Sans', 'Arial', sans-serif;
}
