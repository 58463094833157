@import "scss/fonts.scss";
@import "scss/variables.scss";
@import "scss/breakpoints";
@import "scss/buttons";
@import "scss/custom-inputs";
@import "scss/quill";
@import "scss/recorder-style-overrides";
@import "scss/ng-select";

:root {
  --color-primary: #{map-get($colors, "primary")};
  --color-button-primary: #{map-get($colors, "white")};
}

* {
  box-sizing: border-box;
}

.loader-active {
  max-height: 100%;
  overflow: hidden;
}

[class^="icon-"],
[class*=" icon-"] {
  vertical-align: middle;
}
html {
  height: 100vh;
  scroll-behavior: smooth;

  body {
    @include default-font-settings;
    background: $gray-light;
    height: 100%;
  }
}

.route-card {
  border-radius: 1rem;
  background: $white;
  display: block;
  min-height: 100%;
  margin: 0 2rem;
  width: calc(100% - 4rem);
  padding: 2rem;
  overflow-y: auto;
  @include breakpoint-up-md {
    min-height: 43vh;
    max-height: 65vh;
  }
  p {
    margin: 0;
  }
  &:after,
  &:before {
    display: block;
    width: 1rem;
    height: 70%;
    background: $white;
    content: "";
    position: absolute;
    top: 20%;
    bottom: 20%;
    @include breakpoint-up-md {
      display: none;
    }
  }
  &:before {
    border-radius: 0 1rem 1rem 0;
    left: 0;
  }
  &:after {
    border-radius: 1rem 0 0 1rem;
    right: 0;
  }
  &.first-card {
    &:before {
      display: none;
    }
  }
  &.last-card {
    &:after {
      display: none;
    }
  }

  &.application-complete {
    max-height: none;
    @include breakpoint-up-md {
      margin: 0 auto;
      max-width: 100%;
    }
    @include breakpoint-up-xl {
      margin: 0 auto;
      max-width: 102.4rem;
    }
  }
}

.homepage-background {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

// animation for WhyWorkWithUs arrow under video player
// moved here to appease IE 11
@keyframes bounce {
  0% {
    transform: translateY(0) rotate(-90deg);
  }
  50% {
    transform: translateY(-2rem) rotate(-90deg);
  }
  100% {
    transform: translateY(0) rotate(-90deg);
  }
}

.upload-progress-bar {
  width: 100%;
  margin-top: 1rem;
  z-index: 2000;

  .custom-progress-bar-wrapper {
    width: 100%;
    height: 2rem;
    background-color: $gray-medium;
    border-radius: 0.25rem;
    overflow: hidden;
    position: relative;

    .custom-progress-bar {
      width: 0;
      height: 100%;
      transition: width 0.6s ease-in-out;
      background-color: var(--color-primary);
    }

    .percent {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }

  .optimization-in-progress {
    animation: blinker 2s linear infinite;
    text-align: center;
    margin-top: 1rem;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  button {
    width: 100%;
    margin-top: 1rem;
  }
}

app-video-answer {
  &.upload-in-progress {
    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1002;
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(3px);
    }
  }

  .recorder {
    video {
      width: 100%;
    }
  }
}

.progress-tracker-container {
  width: 100%;
  margin-top: auto;

  .progress-tracker {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    box-sizing: content-box;
    // preventing indicator from beeing "cut off"
    width: calc(100% - 2.2rem);
    max-width: 48.4rem;
    height: 2.2rem;
    margin: 0 auto;

    .track {
      flex: 1 1 auto;
      height: 0.5rem;
      background-color: $gray-medium;
    }

    .progress {
      height: 0.5rem;
      width: 0;
      background-color: var(--color-primary);
    }

    .indicator {
      position: absolute;
      left: calc(0% - 1.1rem);
      height: 2.2rem;
      width: 2.2rem;
      background-color: var(--color-primary);
      border-radius: 50%;
    }
  }
}
